import type { App } from 'vue'

export function hasPermi(app: App<Element>) {
    app.directive('hasPermi', (el, binding) => {
      const { value } = binding
      const all_permission = '*:*:*'
      const userInfo = JSON.parse(localStorage.getItem("userInfo"))
      const permissions = userInfo.PermissionList
      if (value && value instanceof Array && value.length > 0) {
        const permissionFlag = value
  
        const hasPermissions = permissions.some((permission: string) => {
          return all_permission === permission || permissionFlag.includes(permission)
        })
  
        if (!hasPermissions) {
          el.parentNode && el.parentNode.removeChild(el)
        }
      } else {
        throw new Error("没有权限")
      }
    })
  }
  

import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
      // 为了解决table页面伸缩bug
      const debounce = (callback: (...args: any[]) => void, delay: number) => {
      let tid: any;
      return function (...args: any[]) {
        const ctx = self;
        tid && clearTimeout(tid);
        tid = setTimeout(() => {
          callback.apply(ctx, args);
        }, delay);
        };
      };
      const project_id = 1
      const _ = (window as any).ResizeObserver;
      (window as any).ResizeObserver = class ResizeObserver extends _ {
        constructor(callback: (...args: any[]) => void) {
          callback = debounce(callback, 20);
          super(callback);
        }
      };
  },
})

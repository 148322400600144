import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import userRoutes from './userManage'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "首页",
    },
    children: [
      {
        path: 'index',
        name: "index",
        component: () => import(/* webpackChunkName: "index" */ '../views/home/index.vue')
      },
      ...userRoutes,
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/userMange/loginManage/LoginView.vue')
  }, 

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

import axios, {
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from "axios";

import router from '@/router/index'

import { ElMessage } from "element-plus";

enum MSGS {
  "接口不存在" = 404,
  "请求授权失败" = 401,
  "成功" = 2000,
  "失败" = -1,
  "数据库错误" = 4001,
  "无数据" = 4002,
  "数据已存在" = 4003,
  "用户未登录" = 4101,
  "用户登录失败" = 4102,
  "参数错误" = 4103,
  "用户不存在或未激活" = 4104,
  "用户身份错误" = 4105,
  "密码错误" = 4106,
  "验证信息为空" = 4107,
  "非法请求或请求次数受限" = 4201,
  "IP受限" = 4202,
  "第三方系统错误" = 4301,
  "数据校验" = 4001,
  "系统错误" = 0,
}

// 创建http实例
const $http = axios.create({
  // baseURL: "http://127.0.0.1:8002/api/auth",
  baseURL: "https://invengo-auth.rfidtour.com/api/auth",
  timeout: 20000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
});

// 请求拦截
$http.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => {
  config.headers = config.headers;
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    config.headers.Authorization = accessToken;
  } else {
    // 如果没有 accessToken，重定向到登录页面
    router.push('/login');
  }
  return config;
});

// 响应
$http.interceptors.response.use(
  (response) => {
    const { code, data, msg } = response.data;

    if (code === 2000) {
      return response.data;
    } else {
      let errorMessage = msg || MSGS[code] || '未知错误';

      switch (code) {
        case 401:
          // 清除用户登录状态
          // logout();  // 假设你有一个 logout 函数来清除用户状态
          router.push('/login');
          errorMessage = '登录已过期，请重新登录';
          break;
        case -1:
          // 特殊错误，使用服务器返回的消息
          break;
        default:
          // 使用预定义的错误消息或默认消息
          break;
      }

      ElMessage.error(errorMessage);
      return Promise.reject({ code, msg: errorMessage, data });
    }
  },
  (error) => {
    let errorMessage = '网络错误，请稍后重试';

    if (error.response) {
      // 服务器返回了错误状态码
      errorMessage = MSGS[error.response.status] || `请求失败: ${error.response.status}`;
    } else if (error.request) {
      // 请求已经发出，但没有收到响应
      errorMessage = '服务器无响应，请检查网络';
    }

    ElMessage.error(errorMessage);
    return Promise.reject(error);
  }
);

export default $http
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, KeepAlive as _KeepAlive, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.isDay ? 'container-day' : 'container-night')
  }, [
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.editableTabsValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editableTabsValue) = $event)),
      type: "card",
      class: _normalizeClass(_ctx.isDay ? 'day' : 'night'),
      closable: "",
      onTabRemove: _ctx.removeTab,
      onTabClick: _ctx.clickTab
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.TabsList, (item) => {
            return (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: item.name,
              label: item.title,
              name: item.name
            }, null, 8, ["label", "name"]))
          }), 128))
        ], 1024))
      ]),
      _: 1
    }, 8, ["modelValue", "class", "onTabRemove", "onTabClick"])
  ], 2))
}
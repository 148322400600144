import $http from "../https/index"

export interface  LoginJosnInt {
    user_name: string
    password: string
    project_id: number
}

export const loginFunc = (data: LoginJosnInt) => $http({url: "/userManage/loginByPwd", method: "post", data})
export const getUserList = (data: any) => $http({url: "/userManage/auth/getUserList", method: "get", params: data})
export const getUserRoleIds = (data: any) => $http({url: "/userManage/auth/getRoleIds", method: "get",params: data})
export const updateRole = (data: any) => $http({url: "/userManage/auth/updateRole", method: "put", data})
export const getUserTypeList = (data: any) => $http({url: "/userManage/auth/getTypeList", method: "get",params: data})
export const getUserTypeOrg = (data: any) => $http({url: "/userManage/auth/getUserTypeOrg", method: "get",params: data})
export const addUser = (data: any) => $http({url: "/userManage/auth/addUser", method: "post",data})
export const getUserMenu = (data: any) => $http({url: "/userManage/auth/getUserMenu", method: "get",params:data})
export const getUserInfo = (data: any) => $http({url: "/userManage/auth/getUserInfo", method: "get",params:data})

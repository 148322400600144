
import { defineComponent, toRefs } from "vue";
export default defineComponent({
  emits: ["childEvent", "sendDay"],
  props: {
    isCollapse: {
      type: Boolean,
      required: true,
    },
    isDay: {
      type: Boolean,
      required: true,
    },
  },
  name: "headerTop",
  setup(props, { emit, attrs, slots }) {
    const { isCollapse, isDay } = toRefs(props);
    const sendMsg = () => {
      emit("childEvent", !isCollapse.value);
    };
    const sendDay = () => {
      emit("sendDay", !isDay.value);
    };
    return {
      sendMsg,
      sendDay
    };
  },
});

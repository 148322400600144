import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-551a91a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "left" }
const _hoisted_3 = { class: "right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isCollapse)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.sendMsg && _ctx.sendMsg(...args))),
            class: "iconfont icon-outdent"
          }))
        : (_openBlock(), _createElementBlock("i", {
            key: 1,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.sendMsg && _ctx.sendMsg(...args))),
            class: "iconfont icon-indent"
          }))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.isDay)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.sendDay && _ctx.sendDay(...args))),
            class: "iconfont icon-lieri"
          }))
        : (_openBlock(), _createElementBlock("i", {
            key: 1,
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.sendDay && _ctx.sendDay(...args))),
            class: "iconfont icon-yejing"
          }))
    ])
  ]))
}
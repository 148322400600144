import { createApp, provide } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router'
import store from './store'; // Import your Vuex store
import { setupAuth } from './directives'

const app = createApp(App)
// 配置项目的ID
app.config.globalProperties.$project_id = 4
// 设置权限
setupAuth(app)
app.use(router).use(store).use(ElementPlus).mount('#app')


const userRoutes: Array<any> = [
  {
    path: "user",
    name: "user",
    meta: {
      isShow: true,
      title: "用户管理",
      icon: "iconfont icon-zhanghaoquanxianguanli",
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/userMange/userManage/UserView.vue"),
  },
  {
    path: "role",
    name: "role",
    meta: {
      isShow: true,
      title: "角色管理",
      icon: "iconfont icon-tianshenpi",
    },
    component: () =>
      import(/* webpackChunkName: "role" */ "../views/userMange/roleManage/RoleView.vue"),
  },
  {
    path: "menu",
    name: "menu",
    meta: {
      isShow: true,
      title: "菜单管理",
      icon: "iconfont icon-yingyongguanli",
    },
    component: () =>
      import(/* webpackChunkName: "menu" */ "../views/userMange/menuManage/MenuView.vue"),
  },
  {
    path: "organization",
    name: "organization",
    meta: {
      isShow: true,
      title: "组织架构管理",
      icon: "iconfont icon-zuzhijiagou",
    },
    component: () =>
      import(/* webpackChunkName: "org" */ "../views/userMange/orgManage/OrgView.vue"),
  },
  {
    path: "organization",
    name: "organization",
    meta: {
      isShow: true,
      title: "组织架构管理",
      icon: "iconfont icon-zuzhijiagou",
    },
    component: () =>
      import(/* webpackChunkName: "org" */ "../views/userMange/orgManage/OrgView.vue"),
  },
  {
    path: "organizationType",
    name: "organizationType",
    meta: {
      isShow: true,
      title: "组织架构类型配置",
      icon: "iconfont icon-type",
    },
    component: () =>
      import(/* webpackChunkName: "org" */ "../views/userMange/orgTypeManage/index.vue"),
  },
  {
    path: "projectConf",
    name: "projectConf",
    meta: {
      isShow: true,
      title: "项目配置管理",
      icon: "iconfont icon-xiangmu",
    },
    component: () =>
      import(/* webpackChunkName: "org" */ "../views/userMange/projectManage/index.vue"),
  },
  {
    path: "interfaceManage",
    name: "interfaceManage",
    meta: {
      isShow: true,
      title: "接口管理",
      icon: "iconfont icon-jiekou",
    },
    component: () =>
      import(/* webpackChunkName: "org" */ "../views/userMange/interfaceManage/index.vue"),
  },
  
];
export default userRoutes;
